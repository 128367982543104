
import Vue from "vue"
import Saisirdate from "../components/SaisirDate.vue"
import axios from "axios"
import { dateFromString, dateHeureFrancaise, dateVersChaine } from "@/plugins/variables"
import { ChecklisteControle, IUtilisateur, Poste } from "@/store/types"

const aujourdhui = dateVersChaine(new Date())

export default Vue.extend({
  name: "Poste",
  components: { Saisirdate },
  data: () => ({
    postes: [] as Poste[],
    aujourdhui: aujourdhui,
    unsubscribe: null as any,
    snak_visible: false,
    snakbar_text: "",
    dictionnaire: [] as string[]
  }),
  methods: {
    initialiser() {
      axios
        .post(this.$store.state.ajaxurl + "Poste.php", {
          action: "INI_POSTE",
          date: this.aujourdhui
        })
        .then(response => {
          this.choixLangue(this.$store.state.langue)
          if (response.data.postes) {
            this.postes = response.data.postes.map((p: Poste) => {
              return new Poste(p)
            })
          }
        })
    },
    changerJour(nbJour: number) {
      const date = dateFromString(this.aujourdhui)
      this.aujourdhui = dateVersChaine(new Date(date.getFullYear(), date.getMonth(), date.getDate() + nbJour, 12))
      this.initialiser()
    },
    trouverNomPrenomUtilisateurs(ut_id: number) {
      const unUtilisateur = this.$store.state.salaries.find((s: IUtilisateur) => s.ut_id == ut_id)
      if (unUtilisateur !== undefined) {
        return unUtilisateur.ut_nom + " " + unUtilisateur.ut_prenom
      }
      return "USER"
    },
    dateHeureFrancaise(date: Date | string) {
      return dateHeureFrancaise(date)
    },
    aUneCheckListe(unPoste: Poste) {
      if (unPoste.getControle().length == 0) {
        return false
      }
      return true
    },
    ouvrirCheckliste(unPoste: Poste) {
      unPoste.afficherCheckListe = !unPoste.afficherCheckListe
      if (this.aUneCheckListe(unPoste) == false) {
        this.snackbarVisible("Ce poste n'a effectuer aucune checkliste")
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    affichageLibelle(unControle: ChecklisteControle) {
      return unControle.getLibelleLangue(this.$store.state.langue)
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Date", //0
            "Utilisateur", //1
            "Service", //2
            "Début de poste", //3
            "Fin de poste", //4
            "Observation", //5
            "Poste en cours", //6
            "Contrôle", //7
            "Réponse", //8
            "Commentaire", //9
            "Oui", //10
            "Non" //11
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Datum", //0
            "Benutzer", //1
            "Dienst", //2
            "Beginn der Stelle", //3
            "Ende der Stelle", //4
            "Beobachtung", //5
            "Aktuelle Stelle", //6
            "Kontrolle", //7
            "Antwort", //8
            "Kommentar", //9
            "Ja", //10
            "Nein" //11
          ]
      }
    }
  },
  created() {
    this.initialiser()
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
